<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import appConfig from "@/app.config";
import {mapGetters} from "vuex";

export default {
    name: "App",

    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === "function" ? title(this.$store) : title;
            return title ? `${title} | ${appConfig.title}` : appConfig.title;
        },
    },

    computed: {
        ...mapGetters({
            alerts: 'app/alerts'
        })
    },

    methods: {
        setRouteAliases: function () {
            const routes = this.$router.options.routes;
            const aliases = this.$i18n.t('routes');

            for (let prop in aliases) {
                routes.map(route => {
                    if (prop === route.name) {
                        if (route.alias && route.alias.length && !route.alias.includes(aliases[prop])) {
                            route.alias = [...route.alias, aliases[prop]]
                        } else if (!route.alias || (route.alias && !route.alias.length)) {
                            route.alias = [aliases[prop]];
                        }
                        this.$router.addRoute(route);
                    }
                })
            }
        }
    },
    watch: {
        '$i18n.locale': {
            deep: true,
            handler: function () {
                this.setRouteAliases();
            },
        },

        'alerts': function (val, oldVal) {
            const length = val.length, oldLength = oldVal.length;

            if (length > oldLength) {
                val.slice(length - (length - oldLength)).forEach(item => {
                    switch (item.style) {
                        case 'success':
                            this.$alertify.success(item.message);
                            break;
                        case 'danger':
                            this.$alertify.error(item.message);
                            break;
                        case 'warning':
                            this.$alertify.warning(item.message);
                            break;
                        default:
                            this.$alertify.message(item.message);
                    }
                });
            }
        },
    },
    created() {
        this.setRouteAliases()
    }
};
</script>
