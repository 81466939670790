import { smsService } from '@/helpers/sms.service';
import i18n from "@/i18n";

export const state = {
    loading: false,
    toList: [],
    out: [],
    in: [],
    inTimeout: null,
    outTimeout: null,
    toListTimeout: null,
    outPager: {},
    inPager: {},
    toListPager: {}
}
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    out: function (state) {
        return state.out;
    },
    in: function (state) {
        return state.in;
    },
    toList: function (state) {
        return state.toList;
    },
    outPager: function (state) {
        const pager = JSON.parse(JSON.stringify(state.outPager));

        pager.current_page = pager && pager.page && pager.page.current;
        pager.last_page = pager && pager.page && pager.page.last;
        pager.column = pager && pager.sorting && pager.sorting.column;
        pager.desc = pager && pager.sorting && pager.sorting.desc;

        return pager;
    },
    inPager: function (state) {
        const pager = JSON.parse(JSON.stringify(state.inPager));

        pager.current_page = pager && pager.page && pager.page.current;
        pager.last_page = pager && pager.page && pager.page.last;
        pager.column = pager && pager.sorting && pager.sorting.column;
        pager.desc = pager && pager.sorting && pager.sorting.desc;

        return pager;
    },
    toListPager: function (state) {
        const pager = JSON.parse(JSON.stringify(state.toListPager));

        pager.current_page = pager && pager.page && pager.page.current;
        pager.last_page = pager && pager.page && pager.page.last;
        pager.column = pager && pager.sorting && pager.sorting.column;
        pager.desc = pager && pager.sorting && pager.sorting.desc;

        return pager;
    }
}
export const mutations = {
    'SET_OUT_LIST': function (state, list) {
        state.out = list;
    },
    'SET_IN_LIST': function (state, list) {
        state.in = list;
    },
    'SET_OUT_PAGER': function (state, pager) {
        state.outPager = pager;
    },
    'SET_IN_PAGER': function (state, pager) {
        state.inPager = pager;
    },
    'ADD_TO_LIST': function (state, item) {
        if (!state.toList) {
            state.toList = [];
        }
        state.toList.unshift(item);
    },
    'SET_TO_LIST_LIST': function (state, list) {
        state.toList = list;
    },
    'SET_TO_LIST_PAGER': function (state, pager) {
        state.toListPager = pager;
    },
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
    'REVOKE': function (state, id) {
        const index = state.toList.findIndex(item => item.id === id);

        state.toList[index].is_deleted = 1;
    },
    'SET_IN_TIMEOUT': function (state, func) {
        if (state.inTimeout) {
            clearTimeout(state.inTimeout);
        }

        state.inTimeout = setTimeout(func, 500);
    },
    'SET_OUT_TIMEOUT': function (state, func) {
        if (state.outTimeout) {
            clearTimeout(state.outTimeout);
        }

        state.outTimeout = setTimeout(func, 500);
    },
    'SET_TO_LIST_TIMEOUT': function (state, func) {
        if (state.toListTimeout) {
            clearTimeout(state.toListTimeout);
        }

        state.toListTimeout = setTimeout(func, 500);
    },
}
export const actions = {
    fetchOut: function ({commit}, pager) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);
        commit('SET_OUT_LIST', []);

        smsService.fetchOutgoingSMS(pager).then(data => {
            commit('SET_OUT_LIST', data.data);
            commit('SET_OUT_PAGER', data.params);
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    fetchIn: function ({commit}, pager) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);
        commit('SET_IN_LIST', []);

        smsService.fetchIncomingSMS(pager).then(data => {
            commit('SET_IN_LIST', data.data);
            commit('SET_IN_PAGER', data.params);
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    fetchInDelayed: function ({commit, dispatch}, pager) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);
        commit('SET_IN_TIMEOUT', () => {
            dispatch('fetchIn', pager);
        });
    },

    fetchOutDelayed: function ({commit, dispatch}, pager) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);
        commit('SET_OUT_TIMEOUT', () => {
            dispatch('fetchOut', pager);
        });
    },

    fetchToList: function ({commit}, pager) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);
        commit('SET_TO_LIST_LIST', []);

        smsService.fetchToListSentSMS(pager).then(data => {
            commit('SET_TO_LIST_LIST', data.data);
            commit('SET_TO_LIST_PAGER', data.params);
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    fetchToListDelayed: function ({commit, dispatch}, pager) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);
        commit('SET_TO_LIST_TIMEOUT', () => {
            dispatch('fetchToList', pager);
        });
    },

    send: function ({commit}, data) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        if (!data.message || !(data.phone_number || (data.country_code && data.area_code && data.number))) {
            commit('app/ADD_ALERT', {
                message: i18n.t('sms.missing_parameter'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
            return;
        }

        return smsService.sendSMS(data)
            .then(response => {
                commit('app/ADD_ALERT', {
                    message: i18n.t('sms.message_sent'),
                    style: 'success',
                    countDown: 5
                }, { root: true });
                commit('app/SET_LOADING', false, { root: true});
                commit('SET_LOADING', false);
                return response;
            }).catch(error => {
                commit('app/ADD_ALERT', {
                    message: error?.response?.data?.errorMSG || i18n.t('request.error'),
                    style: 'danger',
                    countDown: 5
                }, { root: true });
                commit('app/SET_LOADING', false, { root: true});
                commit('SET_LOADING', false);
                return error?.response?.data;
            });
    },

    sendToList: function ({commit}, data) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        if (!data.id_contact_list) {
            commit('app/ADD_ALERT', {
                message: i18n.t('validate.required', {item: i18n.t('contact.contact_list')}),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
            return;
        }

        if (!data.message) {
            commit('app/ADD_ALERT', {
                message: i18n.t('validate.required', {item: i18n.t('sms.message')}),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
            return;
        }

        return smsService.sendSMStoList(data)
            .then(response => {
                commit('ADD_TO_LIST', response.response);
                commit('app/ADD_ALERT', {
                    message: i18n.t('sms.message_sent'),
                    style: 'success',
                    countDown: 5
                }, { root: true });
                commit('app/SET_LOADING', false, { root: true});
                commit('SET_LOADING', false);
                return response;
            }).catch(error => {
                commit('app/ADD_ALERT', {
                    message: error?.response?.data?.errorMSG ||  i18n.t('request.error'),
                    style: 'danger',
                    countDown: 5
                }, { root: true });
                commit('app/SET_LOADING', false, { root: true});
                commit('SET_LOADING', false);
                return error?.response?.data;
        });
    },

    revokeSentToList: function ({commit}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        if (!id) {
            commit('app/ADD_ALERT', {
                message: i18n.t('validate.required', {item: 18n.t('contact.contact.list')}),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
            return;
        }

        smsService.revokeSMStoList({id_list_send: id})
            .then(() => {
                commit('REVOKE', id);
                commit('app/ADD_ALERT', {
                    message: i18n.t('sms.message_revoked'),
                    style: 'success',
                    countDown: 5
                }, { root: true });
                commit('app/SET_LOADING', false, { root: true});
                commit('SET_LOADING', false);
            }).catch(error => {
            commit('app/ADD_ALERT', {
                message: error?.response?.data?.errorMSG || i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },
};
