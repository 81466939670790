
export const balanceService = {
    all,
    balance
};

function all () {
    const endpoint = `/credits/listBalance`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(endpoint, requestOptions)
        .then(handleResponse);
}

function balance() {
    const endpoint = `/credits/getBalance`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data && response.data.balance;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(endpoint, requestOptions)
        .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            // if (response.status === 401) {}
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
