import { addressService } from '@/helpers/address.service';
import i18n from "@/i18n";

export const state = {
    loading: false,
    list: [],
    item: {}
}
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    list: function (state) {
        return state.list;
    },
    item: function (state) {
        return JSON.parse(JSON.stringify(state.item));
    }
}
export const mutations = {
    'ADD_ITEM': function (state, item) {
        if (!state.list) {
            state.list = [];
        }

        state.list.unshift(item);
    },
    'REMOVE_ITEM': function (state, id) {
        const index = state.list.findIndex(listed => listed.m_id === id);

        if (index !== -1) {
            state.list.splice(index, 1);
        }
    },
    'REPLACE_ITEM': function (state, item) {
        const index = state.list.findIndex(listed => listed.m_id === item.id);

        if (index !== -1) {
            state.list[index] = item;
        }
    },
    'SET_LIST': function (state, list) {
        state.list = list;
    },
    'SET_ITEM': function (state, item = {}) {
        state.item = item;
    },
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
}
export const actions = {
    emptyItem: function ({commit}) {
        commit('SET_ITEM');
    },
    fetchList: function ({commit}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return addressService.fetchList(id).then(data => {
            commit('SET_LIST', data);
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    fetchOne: function ({commit}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return addressService.fetchItem(id).then(data => {
            commit('SET_ITEM', data);
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    create: function ({commit}, payload) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return addressService.store(payload).then(data => {
            const model = Object.assign({}, payload, data);

            commit('ADD_ITEM', model);
            commit('SET_ITEM', model);
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    update: function ({commit}, payload) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        const id = payload.m_id;

        const data = JSON.parse(JSON.stringify(payload));

        delete data.m_id;

        for (let prop in payload) {
            if (state.item[prop] === payload[prop]) {
                delete data[prop]
            }
        }

        return addressService.update(id, payload).then(data => {
            commit('REPLACE_ITEM', data);
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    destroy: function ({commit}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return addressService.destroy(id).then(response => {
            commit('REMOVE_ITEM', id);
            commit('SET_ITEM', {});
            commit('app/ADD_ALERT', {
                message: i18n.t('request.delete_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            return response;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.delete_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    choose: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        const item = state.list.find(item => item.m_id === id);

        if (item) {
            commit('SET_ITEM', item);
            return item;
        } else {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
        }

        commit('app/SET_LOADING', false, { root: true});
        commit('SET_LOADING', false);
    },

    importList: function ({ commit }, file) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        const formData = new FormData()

        formData.append('file', file)

        return addressService.importList(formData).then(data => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    exportList: function () {

    },
};

export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
};
