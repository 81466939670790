import { authHeader } from './authservice/auth-header';

export const notificationService = {
    all,
    destroye,
    setItemRead,
    setAllRead,
    refresh
};

function all() {
    const endpoint = `/message/listMessages`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function destroye(id) {
    const endpoint = `/message/deleteMessage`;

    if (window.axios) {
        return window.axios.delete(endpoint, {id: id}).then(response => {
            return response.data.data;
        });
    }

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({id: id})
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function setItemRead(id) {
    const endpoint = `/message/viewMessage`;

    if (window.axios) {
        return window.axios.post(endpoint, {id: id}).then(response => {
            return response.data.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({id: id})
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function setAllRead() {
    const endpoint = `/message/setAllMessageView`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function refresh() {
    const endpoint = `/message/refreshMessages`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
