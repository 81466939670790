import { userService } from '../../helpers/authservice/user.service';
import router from '../../router/index'
import i18n from "@/i18n";

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const getters = {
    loggedIn: function (state) { return state.status.loggeduser},
    availableReceive: function (state) {
        if (!state.status.loggeduser) {
            return false
        }

        return state.user.receive_number || state.user.receive_prefix
    }
};

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        userService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    // Logout the user
    logout({ commit }) {
        userService.logout()
            .then(() => {
                commit('logout');
                commit('notification/CLEAR_INTERVAL', null, { root: true });
            });
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    // reset password
    forgetpassword({ dispatch, state, commit }, { email } = {}) {
        if (state.status.loggeduser) return dispatch('validate')

        return userService.forgetPassword(email).then((response) => {
            if (response && response.data) {
                commit('app/ADD_ALERT', {
                    message: response.data.status ? response.data.content : response.data.errorMSG,
                    style: response.data.status ? 'success' : 'danger',
                    countDown: 5
                }, { root: true});
            }
            return response
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'success',
                countDown: 5
            }, { root: true})
        });
    },
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

