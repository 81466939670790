import { userService } from '../../helpers/authservice/user.service';
import i18n from "@/i18n";

export const state = {
    currentUser: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : {},
    loading: false
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue || {}
        if (newValue) {
            saveState('user', newValue);
        } else {
            emptyState('user');
        }
    },

    UPDATE_2FA(state, bool) {
        state.currentUser.is_two_factor_auth = bool;
        saveState('user', state.currentUser);
    },

    SET_LOADING(state, loading) {
        state.loading = loading
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!(state.currentUser && state.currentUser.id)
    },
    // Get current logged in user.
    currentUser(state) {
        return JSON.parse(JSON.stringify(state.currentUser));
    },
    // Get receive setting
    availableReceive: function (state) {
        if (!state.currentUser) {
            return false
        }

        return state.currentUser.receive_number || state.currentUser.receive_prefix
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        window.localStorage.setItem('SimpleSMSUser', email);

        return userService.login(email, password).then((response) => {
            if (! response.two_factor_authentication) {
                commit('SET_CURRENT_USER', response);
            }

            return response;
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            userService.logout().then((response) => {
                commit('SET_CURRENT_USER', null);
                commit('notification/CLEAR_INTERVAL', null, { root: true })
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    },

    // register the user
    /*register({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().registerUser(email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },*/

    // reset user password
    forgetPassword({ commit, dispatch, getters }, data) {
        if (getters.loggedIn) return dispatch('validate')

        return userService.forgetPassword(data).then((response) => {
            commit('app/ADD_ALERT', {
                message: response?.data?.content || i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            return response
        });
    },

    twoFactorLogin({ commit }, hash) {
        const email = window.localStorage.getItem('SimpleSMSUser');

        return userService.twoFactorLogin({email: email, hash: hash}).then((response) => {
            const user = response;
            commit('SET_CURRENT_USER', user);
            return user;
        });
    },

    change2FA({ commit }, bool) {
        return userService.change2FA({is_two_factor_auth: bool}).then((response) => {
            commit('UPDATE_2FA', response.is_two_factor_auth);
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });
            return response;
        }).catch((error) => {
            commit('app/ADD_ALERT', {
                message: error?.response?.data?.errorMSG || i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, data) {
        if (getters.loggedIn) return dispatch('validate')

        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return userService.resetPassword(data).then((response) => {
            const success = response?.data?.status === true;
            const msg = success ? response?.data?.content : response?.data?.errorMSG;
            const style = success ? 'success' : 'danger';

            commit('app/ADD_ALERT', {
                message: msg,
                style: style,
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);

            return response;
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = (!sessionStorage.getItem('user') ? null : JSON.parse(sessionStorage.getItem('user')));
        commit('SET_CURRENT_USER', user)
        return user;
    },

    changePassword: function ({commit}, payload) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return userService.changePassword(payload).then(data => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch((error) => {
            commit('app/ADD_ALERT', {
                message: error?.response?.data?.errorMSG || i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    updateCurrentUser: function ({ commit, getters }, payload) {
        commit('SET_CURRENT_USER', Object.assign({}, getters.currentUser, payload))
    },
}

// ===
// Private helpers
// ===

function emptyState(key) {
    window.localStorage.removeItem(key, JSON.stringify(state));
    window.sessionStorage.removeItem(key, JSON.stringify(state));
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
    window.sessionStorage.setItem(key, JSON.stringify(state));
}
