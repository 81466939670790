import { statisticsService } from '../../helpers/statistics.service';
import i18n from "@/i18n";

export const state = {
    loading: false,
    smsLoading: false,
    apiUsersLoading: false,
    smsMonthly: [],
    apiUsersMonthly: []
}
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    smsLoading: function (state) {
        return !!state.smsLoading;
    },
    apiUsersLoading: function (state) {
        return !!state.apiUsersLoading;
    },
    sms: function (state) {
        return state.smsMonthly;
    },
    users: function (state) {
        return state.apiUsersMonthly;
    },
}
export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
    'SET_SMS_LOADING': function (state, boolean = true) {
        state.smsLoading = boolean;
    },
    'SET_API_USERS_LOADING': function (state, boolean = true) {
        state.apiUsersLoading = boolean;
    },
    'SET_SMS_MONTHLY': function (state, list) {
        state.smsMonthly = list;
    },
    'SET_USERS_MONTHLY': function (state, list) {
        state.apiUsersMonthly = Array.isArray(list) ? list : [];
    }
}
export const actions = {
    // eslint-disable-next-line no-empty-pattern
    downloadSMSStatistics: function ({}, payload) {
        return statisticsService.downloadSMSStat(payload);
    },

    // eslint-disable-next-line no-empty-pattern
    downloadUserStatistics: function ({}, payload) {
        return statisticsService.downloadUserStat(payload);
    },

    fetchMonthlyStatistics: function ({commit}) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_SMS_LOADING', true);
        commit('SET_LOADING', true);

        return statisticsService.getMonthlySMSStat().then(data => {
            commit('SET_SMS_MONTHLY', data);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            commit('SET_SMS_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
            commit('SET_SMS_LOADING', false);
        });
    },

    fetchApiUsersMonthlyStatistics: function ({commit}, id) {
        if (id) {
            commit('app/SET_LOADING', true, {root: true});
            commit('SET_LOADING', true);
            commit('SET_API_USERS_LOADING', true);

            return statisticsService.getMonthlyUserStat(id).then(data => {
                commit('SET_USERS_MONTHLY', data);
                commit('app/SET_LOADING', false, {root: true});
                commit('SET_LOADING', false);
                commit('SET_API_USERS_LOADING', false);
                return data;
            }).catch(() => {
                commit('app/ADD_ALERT', {
                    message: i18n.t('request.error'),
                    style: 'danger',
                    countDown: 5
                }, { root: true });
                commit('app/SET_LOADING', false, { root: true});
                commit('SET_LOADING', false);
                commit('SET_API_USERS_LOADING', false);
            });
        } else {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
        }
    },

    emptyMonthlyStatistics: function ({commit}) {
        commit('SET_SMS_MONTHLY', []);
    },

    emptyApiUsersMonthlyStatistics: function ({commit}) {
        commit('SET_USERS_MONTHLY', []);
    },
};
