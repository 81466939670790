import {balanceService} from '../../helpers/balance.service';
import i18n from "@/i18n";

export const state = {
    loading: false,
    list: [],
    balance: 0
};
export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
    'SET_BALANCE'(state, value = 0) {
        state.balance = value;
    },
    'SET_LIST'(state, items) {
        state.list = items;
    }
};
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    balance: function (state) {
        return state.balance;
    },
    list: function (state) {
        return state.list;
    }
};
export const actions = {
    init({dispatch}) {
        dispatch('fetchBalance')
    },

    all: function ({commit}) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return balanceService.all().then(data => {
            commit('SET_LIST', data);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    fetchBalance: function ({commit}) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return balanceService.balance().then(data => {
            commit('SET_BALANCE', data);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },
};
