export function objToFormData (obj = {}, formData = new FormData(), key = '') {
    if (!([Array, File, Object].includes(obj.constructor))) {
        return formData;
    }

    // Handle File recursive
    if (obj instanceof File) {
        formData.append(key, obj);
        return formData;
    }

    for (const prop in obj) {
        // Validate value type
        if (obj[prop] && !([String, Number, Boolean, Array, Object, File].includes(obj[prop].constructor))) {
            continue;
        }

        // Set deep index of prop
        const deepKey = key ? key + `[${prop}]` : prop;

        // Handle array
        if (Array.isArray(obj[prop])) {
            obj[prop].forEach((item, index) => {
                objToFormData(item, formData, `${deepKey}[${index}]`);
            })
            continue;
        }

        (typeof obj[prop] === 'object' && obj[prop] && obj[prop].constructor === Object)
            ? objToFormData(obj[prop], formData, deepKey) // Handle object
            : formData.append(deepKey, [undefined, null].includes(obj[prop]) ? '' : obj[prop]) // Handle string, number, boolean
    }

    return formData;
}
