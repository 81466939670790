import { authHeader } from './auth-header';

export const userService = {
    login,
    logout,
    register,
    getAll,
    forgetPassword,
    fetchOne,
    changePassword,
    resetPassword,
    twoFactorLogin,
    change2FA
};

function fetchOne(id) {
    const endpoint = `/user/data/${id}`;

    if (window.axios) {
        return window.axios.post(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ id: id })
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function login(email, password) {
    localStorage.removeItem('accessToken')

    if (window.axios) {
        return window.axios.post(`/login/login`, { mail: email, password: password }).then(response => {
            return response.data.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

function forgetPassword({email}) {
    const endpoint = `/login/repass`;

    if (window.axios) {
        return window.axios.post(endpoint, { mail: email });
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ mail: email })
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function resetPassword(data) {
    const endpoint = `/login/changePassword`;

    if (window.axios) {
        return window.axios.post(endpoint, data);
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function change2FA(data) {
    const endpoint = `/user/change2FA`;

    if (window.axios) {
        return window.axios.post(endpoint, data);
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function changePassword(passwords) {
    const endpoint = `/user/modPassword`;

    if (window.axios) {
        return window.axios.post(endpoint, passwords);
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(passwords)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('accessToken')

    const endpoint = `/login/logout`;

    if (window.axios) {
        return window.axios.get(endpoint);
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function twoFactorLogin({email, hash}) {
    localStorage.removeItem('accessToken');

    if (window.axios) {
        return window.axios.post(`/login/twoFactorLogin`, { email: email, hash: hash }).then(response => {
            return response.data.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, hash })
    };

    return fetch(`/login/two-factor-ligon`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}
