import { authHeader } from './authservice/auth-header';
import qs from 'qs';

export const smsService = {
    fetchOutgoingSMS,
    fetchIncomingSMS,
    fetchToListSentSMS,
    sendSMS,
    sendSMStoList,
    revokeSMStoList
};

function fetchOutgoingSMS(data) {
    const endpoint = `/SMS/listSMS`;

    if (window.axios) {
        return window.axios.get(endpoint, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function fetchIncomingSMS(data) {
    const endpoint = `/SMS/listReceiveSMS`;

    if (window.axios) {
        return window.axios.get(endpoint, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function fetchToListSentSMS(data) {
    const endpoint = `/SMS/listSMSlistSend`;

    if (window.axios) {
        return window.axios.get(endpoint, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function sendSMS(data) {
    const endpoint = `/SMS/sendSMS`;

    if (window.axios) {
        return window.axios.post(endpoint, data, { headers: {'Content-Type': 'multipart/form-data'} }).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function sendSMStoList(data) {
    const endpoint = `/SMS/sendListSMS`;

    if (window.axios) {
        return window.axios.post(endpoint, data, { headers: {'Content-Type': 'multipart/form-data'} }).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function revokeSMStoList(data) {
    const endpoint = `/SMS/revokeListSMS`;

    if (window.axios) {
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
