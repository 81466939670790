import { priceService } from '@/helpers/price.service';
import i18n from "@/i18n";

export const state = {
    loading: false,
    prices: {}
}
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    prices: function (state) {
        return state.prices;
    }
}
export const mutations = {
    'SET_PRICES': function (state, prices) {
        state.prices = prices;
    },
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
}
export const actions = {
    fetch: function ({commit}, pager) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        priceService.fetch(pager).then(data => {
            commit('SET_PRICES', data.data);
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },
};
