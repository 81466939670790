import { authHeader } from './authservice/auth-header';

export const priceService = {
    fetch,
};

function fetch(data) {
    const endpoint = `/profile/getSMSPrices`;

    if (window.axios) {
        return window.axios.get(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
