import { authHeader } from './authservice/auth-header';

export const statisticsService = {
    getMonthlySMSStat,
    getMonthlyUserStat,
    downloadSMSStat,
    downloadUserStat
};

function downloadUserStat(date) {
    const endpoint = `/profile/downloadApiMontlyStat?p1=` + date;

    if (window.axios) {
        return window.axios.get(endpoint, {responseType: 'blob'}).then(response => {
            window.open(URL.createObjectURL(response.data), '_blank');
            return response.status;
        }).catch(({ status, data }) => {
            console.log(status, data)
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function downloadSMSStat(date) {
    const endpoint = `/profile/downloadMontlyStat?YYYYMM=` + date;

    if (window.axios) {
        return window.axios.get(endpoint, {responseType: 'blob'}).then(response => {
            window.open(URL.createObjectURL(response.data), '_blank');
            return response.status;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function getMonthlySMSStat() {
    const endpoint = `/profile/montlyStat`;

    if (window.axios) {
        return window.axios.post(endpoint).then(response => {
            return response.data;
        })
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function getMonthlyUserStat(id) {
    const endpoint = `/profile/apiMontlyStat/${id}`;

    if (window.axios) {
        return window.axios.post(endpoint).then(response => {
            return response.data;
        }).catch(({ status, data }) => {
            console.log(status, data)
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
