import { messageService } from '@/helpers/message.service';
import i18n from "@/i18n";

export const state = {
    loading: false,
    list: [],
    item: {}
}
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    list: function (state) {
        return state.list;
    },
    item: function (state) {
        return state.item;
    }
}
export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
    'SET_LIST': function (state, list) {
        state.list = list;
    },
    'REMOVE_ITEM': function (state, id) {
        const index = state.list.findIndex(listed => listed.m_id === id);

        if (index !== -1) {
            state.list.splice(index, 1);
        }
    },
    'SET_ITEM': function (state, item) {
        state.item = item || {};
    }
}
export const actions = {
    fetchList: function ({commit}) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return messageService.all().then(data => {
            commit('SET_LIST', data);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    fetchOne: function ({commit}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return messageService.one(id).then(data => {
            commit('SET_ITEM', data);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    destroy: function ({commit}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return messageService.destroye(id).then(data => {
            commit('SET_ITEM', {});
            commit('REMOVE_ITEM', id);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.delete_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.delete_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    }
};
