"use strict";

import Vue from 'vue';
import axios from "axios";
import { objToFormData } from '../helpers/functions';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const apiUrl = `//${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_PORT ? ':' + process.env.VUE_APP_API_PORT : ''}${process.env.VUE_APP_API_PATH ? '/' + process.env.VUE_APP_API_PATH : ''}`

let config = {
  baseURL: apiUrl || process.env.baseURL || process.env.apiUrl || '',
  transformRequest: [function (data) {
      // return (data instanceof FormData ? data : objToFormData(data));
      if (this.method !== 'patch') {
          return (data instanceof FormData ? data : objToFormData(data));
      } else {
          let arrData = [];

          for (let prop in data) {
              arrData.push(prop + '=' + encodeURIComponent(data[prop]));
          }

          return arrData.join('&');
      }
  }],
    // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.headers.Accept = 'application/json'
    if (localStorage.getItem('accessToken')) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    if (response.data && response.data.access_token) {
        localStorage.setItem('accessToken', response.data.access_token)
    }
    return response;
  },
  function(error) {
    // Do something with response error
      if ((error && error.response && error.response.status === 401) && (error && error.response && error.response.data && error.response.data && error.response.data.isTokenExpired === true)) {
          window.vm.$store.dispatch('auth/logOut').then(() => {
              window.vm.$router.push(window.vm.$t('routes.bejelentkezes'))
          });
      }
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, /*options*/) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
