import { authHeader } from './authservice/auth-header';

export const apiAccountService = {
    all,
    one,
    create,
    update,
    destroy
};

function all() {
    const endpoint = `/api/crud`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function one(id) {
    const endpoint = `/api/crud/${id}`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function create(data) {
    const endpoint = `/api/crud`;

    if (window.axios) {
        console.log('AXIOS');
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function update(id, data) {
    const endpoint = `/api/crud/${id}`;

    if (window.axios) {
        return window.axios.patch(endpoint, data, {
         headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(response => {
            return response.data.data;
        });
    }

    let body = [];

    for (let prop in data) {
        body.push(prop + '=' + encodeURIComponent(data[prop]))
    }

    const requestOptions = {
        method: 'PATCH',
        headers: Object.assign( {'Content-Type': 'application/x-www-form-urlencoded'}, authHeader()),
        body: body.join('&')
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function destroy(id) {
    const endpoint = `/api/crud/${id}`;

    if (window.axios) {
        return window.axios.delete(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
