import { notificationService } from '@/helpers/notification.service';
import {messageService} from "@/helpers/message.service";
import i18n from "@/i18n";

export const state = {
    loading: false,
    list: [],
    showLength: 4,
    refresh: false,
    intervalId: null
}
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    list: function (state) {
        return state.list;
    },
    news: function (state) {
        return state.list.filter(item => {
            return Number(item.is_viewed) === 0;
        });
    },
    read: function (state) {
        return state.list.filter(item => {
            return Number(item.is_viewed) === 1;
        }).slice(0, state.showLength - 1);
    },
    alert: function (state) {
        return state.list.some(item => {
            return Number(item.is_viewed) === 0;
        });
    },
    noMore: function (state) {
        return state.list.filter(item => {
            return Number(item.is_viewed) === 1;
        }).length <= state.showLength;
    }
}
export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
    'ADD_TO_LIST': function (state, items) {
        if (!state.list) {
            state.list = [];
        }

        if (Array.isArray(items)) {
            items.forEach(item => {
                state.list.unshift(item);
            })
        } else if (items) {
            state.list.unshift(items);
        }
    },
    'SET_LIST': function (state, list) {
        state.list = list;
    },
    'REMOVE_ITEM': function (state, id) {
        const index = state.list.findIndex(listed => listed.id === id);

        if (index !== -1) {
            state.list.splice(index, 1);
        }
    },
    'SET_ITEM_READ': function (state, id) {
        const index = state.list.findIndex(listed => listed.m_id === id);

        if (index !== -1) {
            state.list[index].is_viewed = 1;
        }
    },
    'SET_ALL_READ': function (state) {
        state.list = state.list.map(item => {
            item.is_viewed = 1;
        });
    },
    'INCREASE_SHOW_LENGTH': function (state, number) {
        number ? state.showLength = number : state.showLength += 3;
    },
    'SET_REFRESH': function (state, boolean = true) {
        state.refresh = boolean
    },
    'SET_INTERVAL': function (state, id) {
        state.intervalId = id;
    },
    'CLEAR_INTERVAL': function (state) {
        clearInterval(state.intervalId);
        state.intervalId = null;
    },
}
export const actions = {
    fetchList: function ({commit, dispatch}) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);
        commit('CLEAR_INTERVAL');
        return notificationService.all().then(data => {
            commit('SET_LIST', data || []);
            commit('SET_INTERVAL', setInterval(() => {
                dispatch('refresh')
            }, 5000));
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    setRead: function ({commit}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);
        return notificationService.setItemRead(id).then(data => {
            commit('SET_ITEM_READ', id);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    changeRead: function ({commit}, id) {
        commit('SET_ITEM_READ', id);
    },

    setAllRead: function ({commit}) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);
        return notificationService.setAllRead().then(data => {
            commit('SET_ALL_READ');
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    more: function ({commit, state}) {
        commit('INCREASE_SHOW_LENGTH', state.showLength + 3);
    },

    refresh: function ({commit}) {
        // commit('app/SET_LOADING', true, {root: true});
        // commit('SET_LOADING', true);
        return messageService.refresh().then(data => {
            if (data) {
                commit('ADD_TO_LIST', data);
            }
            // commit('app/SET_LOADING', false, {root: true});
            // commit('SET_LOADING', false);
            return data;
        }).catch(() => {
            console.log('notifications/refresh: ' . i18n.t('request.error'))
            /*commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });*/
            // commit('app/SET_LOADING', false, { root: true});
            // commit('SET_LOADING', false);
        });
    },
};
