import { invoiceService } from '@/helpers/invoice.service';
import i18n from "@/i18n";

export const state = {
    loading: false,
    list: [],
    item: {}
}
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    list: function (state) {
        return state.list;
    },
    item: function (state) {
        return JSON.parse(JSON.stringify(state.item));
    }
}
export const mutations = {
    'SET_LIST': function (state, list) {
        state.list = list;
    },
    'ADD_TO_LIST': function (state, item) {
        if (!state.list) {
            state.list = [];
        }

        state.list.unshift(item);
    },
    'REMOVE_ITEM': function (state, id) {
        const index = state.list.findIndex(listed => listed.m_id === id);

        if (index !== -1) {
            state.list.splice(index, 1);
        }
    },
    'REPLACE_ITEM': function (state, item) {
        const index = state.list.findIndex(listed => listed.id === item.id);

        if (index !== -1) {
            state.list[index] = item;
        }
    },
    'SET_ITEM': function (state, item) {
        state.item = item || {};
    },
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
}
export const actions = {
    fetchList: function ({commit}) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return invoiceService.all().then(data => {
            commit('SET_LIST', data);

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    fetchOne: function ({commit}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return invoiceService.one(id).then(data => {
            commit('SET_ITEM', data);

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    create: function ({commit}, payload) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return invoiceService.create(payload).then(data => {
            const model = Object.assign({}, payload, data);
            commit('SET_ITEM', model);
            commit('ADD_TO_LIST', model);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    update: function ({commit, state}, payload) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        const id = payload.m_id;

        const data = JSON.parse(JSON.stringify(payload));

        delete data.m_id;

        for (let prop in payload) {
            if (state.item[prop] === payload[prop]) {
                delete data[prop]
            }
        }

        return invoiceService.update(id, data).then(response => {
            commit('SET_ITEM', Object.assign({}, payload, response));
            commit('REPLACE_ITEM', id);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return response;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    destroy: function ({commit}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return invoiceService.destroy(id).then(response => {
            commit('SET_ITEM', {});
            commit('REMOVE_ITEM', id);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.delete_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return response;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.delete_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    choose: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        const item = state.list.find(item => item.m_id === id);

        if (item) {
            commit('SET_ITEM', item);
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
            return item;
        }

        commit('app/ADD_ALERT', {
            message: i18n.t('request.error'),
            style: 'danger',
            countDown: 5
        }, { root: true });
        commit('app/SET_LOADING', false, { root: true});
        commit('SET_LOADING', false);

        return {};
    },

    empty: function ({ commit }) {
        commit('SET_ITEM', {});
    },

    deposit: function ({ commit, dispatch }, payload) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return invoiceService.deposit(payload).then(data => {
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            dispatch('fetchList')

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    // eslint-disable-next-line no-empty-pattern
    download: function ({}, payload) {
        return invoiceService.download(payload);
    },

    // eslint-disable-next-line no-empty-pattern
    print: function ({}, payload) {
        return invoiceService.print(payload);
    },
};
