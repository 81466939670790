import { authHeader } from './authservice/auth-header';
import qs from 'qs';

export const contactService = {
    fetchList,
    fetchSelectList,
    fetchItem,
    fetchContent,
    update,
    store,
    destroy
};

function fetchItem(id) {
    const endpoint = `/contactList/crud/${id}`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function fetchList(data) {
    const endpoint = `/contactList/crud`;

    if (window.axios) {
        return window.axios.get(endpoint, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function fetchSelectList() {
    const endpoint = `/contactList/listContactList`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function fetchContent(id) {
    const endpoint = `contactList/getList/${id}`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);

}

function store(data) {
    const endpoint = `/contactList/crud`;

    if (window.axios) {
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function destroy(id) {
    const endpoint = `/contactList/crud/${id}`;

    if (window.axios) {
        return window.axios.delete(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function update(id, data) {
    const endpoint = `contactList/crud/${id}`;

    if (window.axios) {
        return window.axios.patch(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
