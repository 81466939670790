import { authHeader } from './authservice/auth-header';

export const addressService = {
    fetchList,
    fetchItem,
    store,
    destroy,
    update,
    importList
};

function fetchItem(id) {
    const endpoint = `/contactAddress/crud/${id}`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function fetchList(id) {
    const endpoint = `/contactAddress/listContactAddress/${id}`;

    if (window.axios) {
        return window.axios.get(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function store(data) {
    const endpoint = `/contactAddress/crud`;

    if (window.axios) {
        return window.axios.post(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function update(id, data) {
    const endpoint = `/contactAddress/crud/${id}`;

    if (window.axios) {
        return window.axios.patch(endpoint, data).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function destroy(id) {
    const endpoint = `/contactAddress/crud/${id}`;

    if (window.axios) {
        return window.axios.delete(endpoint).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function importList(data) {
    const endpoint = `/contactAddress/import`;

    if (window.axios) {
        return window.axios.post(endpoint, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        });
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
