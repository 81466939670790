import { contactService } from '../../helpers/contact.service';
import address from './address';
import i18n from "@/i18n";

export const modules = { address };

export const state = {
    loading: false,
    list: [],
    selectList: [],
    item: {},
    timeoutId: null
}
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    list: function (state) {
        return state.list;
    },
    selectList: function (state) {
        return state.selectList;
    },
    item: function (state) {
        return JSON.parse(JSON.stringify(state.item));
    }
}
export const mutations = {
    'ADD_ITEM': function (state, item) {
        if (!state.list) {
            state.list = [];
        }

        state.list.unshift(item);
    },
    'REMOVE_ITEM': function (state, id) {
        const index = state.list.findIndex(listed => listed.m_id === id);

        if (index !== -1) {
            state.list.splice(index, 1);
        }
    },
    'REPLACE_ITEM': function (state, item) {
        const index = state.list.findIndex(listed => listed.m_id === item.id);

        if (index !== -1) {
            state.list[index] = item;
        }
    },
    'SET_LIST': function (state, list) {
        state.list = list;
    },
    'SET_SELECT_LIST': function (state, list) {
        state.selectList = list.map(item => {
            item.formatted = `${item.name} (${item.count})`
            return item
        });
    },
    'SET_ITEM': function (state, item = {}) {
        state.item = item;
    },
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
    'SET_TIMEOUT': function (state, func) {
        if (state.timeoutId) {
            clearTimeout(state.timeoutId)
        }

        state.timeoutId = setTimeout(func, 500);
    },
}
export const actions = {
    emptyItem: function ({commit}) {
        commit('SET_ITEM');
    },

    fetchList: function ({commit}, pager) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return contactService.fetchList(pager).then(data => {
            commit('SET_LIST', data);

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    fetchListDelayed: function ({commit, dispatch}, pager) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);
        commit('SET_TIMEOUT', () => {
            dispatch('fetchList', pager);
        });
    },

    fetchOne: function ({commit}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return contactService.fetchItem(id).then(data => {
            commit('SET_ITEM', data);

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    choose: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        const item = state.list.find(item => item.m_id === id);

        if (item) {
            commit('SET_ITEM', item);
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
            return item;
        }

        commit('app/ADD_ALERT', {
            message: i18n.t('request.error'),
            style: 'danger',
            countDown: 5
        }, { root: true });

        commit('app/SET_LOADING', false, { root: true});
        commit('SET_LOADING', false);

        return {};
    },

    fetchContent: function ({commit}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return contactService.fetchContent(id).then(data => {
            commit('SET_LIST', data);

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    create: function ({commit}, payload) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return contactService.store(payload).then(data => {
            const model = Object.assign({}, payload, data);
            commit('ADD_ITEM', model);
            commit('SET_ITEM', model);

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    update: function ({commit}, payload) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        const id = payload.m_id;

        const data = JSON.parse(JSON.stringify(payload));

        delete data.m_id;

        for (let prop in payload) {
            if (state.item[prop] === payload[prop]) {
                delete data[prop]
            }
        }

        return contactService.update(id, payload).then(data => {
            commit('REPLACE_ITEM', Object.assign({}, payload, data));

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.save_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    destroy: function ({commit}, id) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return contactService.destroy(id).then(response => {
            commit('REMOVE_ITEM', id);
            commit('SET_ITEM', {});

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            commit('app/ADD_ALERT', {
                message: i18n.t('request.delete_success'),
                style: 'success',
                countDown: 5
            }, { root: true });

            return response;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.delete_failed'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },

    fetchSelectList: function ({commit}) {
        commit('app/SET_LOADING', true, { root: true});
        commit('SET_LOADING', true);

        return contactService.fetchSelectList().then(data => {
            commit('SET_SELECT_LIST', data);

            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);

            return data;
        }).catch(() => {
            commit('app/ADD_ALERT', {
                message: i18n.t('request.error'),
                style: 'danger',
                countDown: 5
            }, { root: true });
            commit('app/SET_LOADING', false, { root: true});
            commit('SET_LOADING', false);
        });
    },
};
